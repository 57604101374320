// import Swiper bundle with all modules installed
import Swiper from 'swiper/bundle';

// init Swiper:
const swiper = new Swiper('.swiper', {
	// Optional parameters
	// direction: 'vertical',
	loop: true,
	slidesPerView: 1,
	autoplay: {
		delay: 5000,
	},
	// If we need pagination
	pagination: {
	  el: '.swiper-pagination',
	},

	// Navigation arrows
	// navigation: {
	//   nextEl: '.swiper-button-next',
	//   prevEl: '.swiper-button-prev',
	// },

	// And if we need scrollbar
	// scrollbar: {
	//   el: '.swiper-scrollbar',
	// },
	breakpoints: {
		// 768px以上の場合
		768: {
			slidesPerView: 'auto',
		},
	}
});
