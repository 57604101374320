import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

window.addEventListener('DOMContentLoaded', () => {
	const anchorLinks = document.querySelectorAll('a[href^="#"]');
	const anchorLinksArr = Array.prototype.slice.call(anchorLinks);


	anchorLinksArr.forEach(link => {
		link.addEventListener('click', e => {
			e.preventDefault();
			let headerHeight;
			let targetOffsetTop;
			const windowSize = window.innerWidth;
			const header = document.querySelector('#header');

			const targetId = link.hash;
			const targetElement = document.querySelector(targetId);

			if (targetElement){
				targetOffsetTop = window.pageYOffset + targetElement.getBoundingClientRect().top;
			} else {
				targetOffsetTop = 0;
			}
			if (windowSize < 769) {
				headerHeight = header.offsetHeight;
			} else {
				headerHeight = 0;
			}

			const totalScrollAmount = targetOffsetTop - headerHeight;
			window.scrollTo({
				top: totalScrollAmount,
				behavior: "smooth"
			});
		});
	});
  });