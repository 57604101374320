// import './js/navi.js';
import './js/scroll_fadein.js';
import './js/ancker_link.js';
// import './js/gototop_btn.js';
// import './js/accordion.js';
// import './js/stickyfill.js';
// import './js/tab.js';
import './js/scroll-hint.js';
// import './js/copyUrl.js';
import './js/setFillHeight.js';

import './js/swiper.js';

import './js/org.js';






import "./scss/style.scss";