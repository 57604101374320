'use strict';

// -------------------------------------------------------------
// slick slider
$(document).ready(function(){
  $('.js-case-study-slider').slick({
    infinite: true,
    autoplay: true,
    centerMode: true,
    centerPadding: '20%',
    prevArrow: '<button class="prev-arrow"></button>',
    nextArrow: '<button class="next-arrow"></button>',
    variableWidth: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          centerPadding: "15%",
        },
    }
    ]
  });
});



///////////////////////////////////////
// Sticky navigation: Intersection Observer API

// const header = document.querySelector('.header');
// const nav = document.querySelector('.js-sticky');
// const navHeight = nav.getBoundingClientRect().height;


// const stickyNav = function (entries) {
//   const [entry] = entries;

//   if (!entry.isIntersecting) nav.classList.add('sticky');
//   else nav.classList.remove('sticky');
// };

// const headerObserver = new IntersectionObserver(stickyNav, {
//   root: null,
//   threshold: 0,
//   rootMargin: "0px",
// });

// headerObserver.observe(header);
